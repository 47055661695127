import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../../components/common/BreadcrumbLite';
import styled from 'styled-components';
import Markets from '../../components/featured/markets';
import BreadHome from '../../components/BreadHome'

const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const Gaming = (props) => {


  const renderInvestor = (edge, index) => {

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>
            <BS.Row>
              <BS.Col sm={6}>
                < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name}</Link>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments: <b>{edge.node.gamingk}</b>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  const renderPlayer = (edge, index) => {

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>
            <BS.Row>
              <BS.Col sm={6}>
                < Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name}</Link>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                funds raised: <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.totalRaised)}</b>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Gaming Market By Unicorn Alert</title>
        <meta name="title" content="Gaming Market By Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from Gaming startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 11, maxWidth: 1240 }}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item> <Link to={`/`}>Data</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/market/`}>Markets</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Gaming</BS.Breadcrumb.Item>
      </BreadcrumbLite>
      <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>Gaming Market - private equity and venture capital deals</h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Businesses and investors ranked by investments</h2>
                </BS.Col>
        </BS.Row>
            <BS.Row>

            <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Businesses</BS.Card.Header>
            </BS.Card>
            {props.data.allBusiness.edges.map(renderPlayer)}
          </BS.Col>
          <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Investors</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderInvestor)}
          </BS.Col>

          <BS.Col sm={4} style={{ marginTop: 20 }}>
            <Markets />
          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allBusiness(sort: {order: DESC, fields: totalRaised}, filter: {country: {eq: "UK"}, what: {eq: "gaming"}}) {
    edges {
      node {
        name
        id
        currency
        totalRaised
      }
    }
  }
  allInvestor(filter: {countUK: {gt:0}, gamingk: {gt: 0}}, sort: {fields: gamingk, order: DESC}) {
    edges {
      node {
        name
        gamingk
      }
    }
  }
}
`

export default Gaming
